import React, { useEffect } from 'react'

export default () => {
  useEffect(() => {
    window.location.pathname =
      '/news/tutte-le-news/pala-dolce-casa-al-via-la-campagna-abbonamenti-del-ritorno-al-palabigi'
  }, [])

  return null
}
